import React from "react"
import * as Icon from "react-feather"
import { graphql } from "gatsby"

import CaseDetail from "../../components/Shared/casedetails"

const seoTitle = "Regulatory Anomaly Detection "
const pageTitle = "Regulatory Anomaly Detection"
const subTitle = "Data Science"
const para =
  "Atyeti is a leader in data systems and analytical thinking within the asset management and regulatory space. For 14 years Atyeti has been providing solutions that leverage our expertise in engineering, data analysis, and a deep understanding of the banking industry to provide powerful insights and automation tools to our clients."
// "An advanced analytics group charged with detecting anomalous transactions across the regulatory space."
const blockquote = `"Data analysis, and a deep understanding of the
banking industry to provide powerful insights and
automation tools to our clients."`
//  `"Using Machine Learning and Automation Algorithms to Drive Better Insights."`

// const cardItems = [
//   {
//     id: 1,
//     heading: `Business Challenge:`,
//     listItems: (
//       <>
//         <li>
//           <Icon.Square />
//           Detect both simple and complex anomalies across the regulatory space.
//         </li>
//         <li>
//           <Icon.Square />
//           Provide transparency into why a transaction is deemed anomalous in a
//           way business users can understand and respond to.
//         </li>
//       </>
//     ),
//   },
//   {
//     id: 2,
//     heading: `Technical Solution`,
//     listItems: (
//       <>
//         <li>
//           <Icon.Square />
//           Data feeds come from several sources at various reporting times and
//           with proprietary schemas.
//         </li>
//         <li>
//           <Icon.Square />
//           There is no ‘known anomaly’ data set to train from, yet models must
//           provide a justifiable confidence metric.
//         </li>
//         <li>
//           <Icon.Square />
//           Models must provide transparency into criteria used to identify given
//           anomalies.
//         </li>
//       </>
//     ),
//   },
//   {
//     id: 3,
//     heading: `Results Achieved`,
//     listItems: (
//       <>
//         <p>
//           Atyeti was able to structure an advanced data engineering and
//           analytics team which validated and homogenized the data. By
//           constructing a multi-level analytics pipeline, paired with a complex
//           ensemble of deep learning algorithms, truth determination and
//           explainer networks, we were able to meet all business requirements and
//           technical objectives.
//         </p>
//       </>
//     ),
//   },
// ]
const cardItems = [
  {
    id: 1,
    heading: `Solution Overview`,
    listItems: (
      <>
        <p>An automated approach to regulatory anomaly detection</p>
        <li>
          <Icon.Square />
          Stakeholders register a new control with the RAD UI, upload sample
          data and set configuration.
        </li>
        <li>
          <Icon.Square />
          Sample data is validated, and an analytic plan built. Instructions for
          delivering data is provided.
        </li>
        <li>
          <Icon.Square />
          Per specified schedule, new data is ingested, validated and
          transformed. Any exceptions are reported and handled according to
          configuration.
        </li>
        <li>
          {" "}
          <Icon.Square />
          All models are trained and tested using on-demand clusters, and report
          is generated.
        </li>

        <li>
          {" "}
          <Icon.Square />
          Stakeholders submit feedback either in Tableau using a write-back
          feature, or uploaded as notes in Excel. This feedback is stored for
          the next training cycle.
        </li>
      </>
    ),
  },
  {
    id: 2,
    heading: `Major Development Milestones`,
    listItems: (
      <>
        <li>
          <Icon.Square />
          <strong>Infrastructure/ DevSecOps/ DataOps /MLOps : </strong>
          Streamlined tooling, on-demand scalable compute, end-to-end data
          security, CI/CD automation, QA automation, ML CM/CT/CD automation,
          regional data isolation control, VCP and networking.
        </li>
        <li>
          <Icon.Square />
          <strong>Data Validation/ ETL : </strong>
          Automated schema-based expectation generation, outlier detection, and
          transformation.
        </li>
        <li>
          <Icon.Square />
          <strong>Supported Models/ Continuous Training/ Deployment : </strong>
          Build out of library of supported statistical and learning models,
          expert ruleset for model selection, custom auto-ensemble-ML, automated
          prediction distribution analysis setup, CM/CT/CD.
        </li>
        <li>
          <Icon.Square />
          <strong>Data Management / Governance : </strong>Data quality automation,
          data security, data legacy tracking, storage and retrieval, automated
          pipeline orchestration, data retention / archival process.{" "}
        </li>
        <li>
          <Icon.Square />
          <strong>Management/ Feedback UI : </strong>Visualization write-back
          capability, online feedback submission, feedback consumption and
          management, progressive shift to feedback-based training.
        </li>
        <li>
          <Icon.Square />
          <strong>Analytic Planning/ Control Init : </strong>System administration
          UI, control creation, management and monitoring, exception resolution,
          report generation, analytic plan generation, data submission analysis.
        </li>
      </>
    ),
  },
  // {
  //   id: 3,
  //   heading: `Results Achieved`,
  //   listItems: (
  //     <>
  //       <p>
  //         Atyeti was able to structure an advanced data engineering and
  //         analytics team which validated and homogenized the data. By
  //         constructing a multi-level analytics pipeline, paired with a complex
  //         ensemble of deep learning algorithms, truth determination and
  //         explainer networks, we were able to meet all business requirements and
  //         technical objectives.
  //       </p>
  //     </>
  //   ),
  // },
]
const BlogDetails = ({ data }) => (
  <CaseDetail
    seoTitle={seoTitle}
    pageTitle={pageTitle}
    cardItems={cardItems}
    subTitle={subTitle}
    para={para}
    blockquote={blockquote}
    images={data}
  />
)
export const query = graphql`
  query DataScienceQuery {
    casedetailsJson(slug: { eq: "regulator-anomaly-detection" }) {
      images {
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      slug
    }
  }
`
export default BlogDetails
